import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'husky-app-shared-ui-drawer-header',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './app-shared-ui-drawer-header.component.html',
  styleUrl: './app-shared-ui-drawer-header.component.scss',
  host: {
    class: 'py-3 px-gutter flex items-center gap-4 flex-none border-b',
  },
})
export class AppSharedUiDrawerHeaderComponent {}
