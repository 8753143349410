import { Direction } from '@angular/cdk/bidi';
import { DialogRole } from '@angular/cdk/dialog';
import { ScrollStrategy } from '@angular/cdk/overlay';
import { ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { DialogPosition } from '@angular/material/dialog';

/** Options for where to set focus to automatically on dialog open */
export type AutoFocusTarget = 'dialog' | 'first-tabbable' | 'first-heading';

/** Possible overrides for a drawer's position. */
export type DrawerPosition = 'top' | 'bottom' | 'left' | 'right';

/**
 * Configuration used when opening a drawer.
 */
export class HuskyDrawerConfig<D = any> {
  /** ID for the dialog. If omitted, a unique one will be generated. */
  id?: string;

  /** The view container to place the overlay for the drawer into. */
  viewContainerRef?: ViewContainerRef;

  /** Extra CSS classes to be added to the drawer container. */
  panelClass?: string | string[];

  /** Text layout direction for the drawer. */
  direction?: Direction;

  /** Data being injected into the child component. */
  data?: D | null = null;

  /** Whether the drawer has a backdrop. */
  hasBackdrop?: boolean = true;

  /** Custom class for the backdrop. */
  backdropClass?: string;

  /** Whether the user can use escape or clicking outside to close the drawer. */
  disableClose?: boolean = false;

  /** Aria label to assign to the drawer element. */
  ariaLabel?: string | null = null;

  /** The ARIA role of the dialog element. */
  role?: DialogRole = 'dialog';

  /** ID of the element that describes the dialog. */
  ariaDescribedBy?: string | null = null;

  /** Whether this is a modal dialog. Used to set the `aria-modal` attribute. */
  ariaModal?: boolean = true;

  /**
   * Whether the drawer should close when the user goes backwards/forwards in history.
   * Note that this usually doesn't include clicking on links (unless the user is using
   * the `HashLocationStrategy`).
   */
  closeOnNavigation?: boolean = true;

  /**
   * Where the dialog should focus on open.
   * @breaking-change 14.0.0 Remove boolean option from autoFocus. Use string or
   * AutoFocusTarget instead.
   */
  autoFocus?: AutoFocusTarget | string | boolean = 'first-tabbable';

  /**
   * Whether the dialog should restore focus to the
   * previously-focused element, after it's closed.
   */
  restoreFocus?: boolean = true;

  /** Whether to wait for the opening animation to finish before trapping focus. */
  delayFocusTrap?: boolean = true;

  /** Scroll strategy to be used for the dialog. */
  scrollStrategy?: ScrollStrategy;

  /** Alternate `ComponentFactoryResolver` to use when resolving the associated component. */
  componentFactoryResolver?: ComponentFactoryResolver;

  /** Position of the drawer */
  position?: DialogPosition;

  /** Width of the drawer. If a number is provided, assumes pixel units. */
  width?: string = '';

  /** Height of the drawer. If a number is provided, assumes pixel units. */
  height?: string = '';

  /** Min-width of the drawer. If a number is provided, assumes pixel units. */
  minWidth?: number | string;

  /** Min-height of the drawer. If a number is provided, assumes pixel units. */
  minHeight?: number | string;

  /** Max-width of the drawer. If a number is provided, assumes pixel units. */
  maxWidth?: number | string;

  /** Max-height of the drawer. If a number is provided, assumes pixel units. */
  maxHeight?: number | string;

  /** Whether the drawer should close when the user goes back in history. */
  closeOnBackButton?: boolean = true;

  /**
   * Duration of the enter animation in ms.
   * Should be a number, string type is deprecated.
   * @breaking-change 17.0.0 Remove string signature.
   */
  enterAnimationDuration?: string | number;

  /**
   * Duration of the exit animation in ms.
   * Should be a number, string type is deprecated.
   * @breaking-change 17.0.0 Remove string signature.
   */
  exitAnimationDuration?: string | number;
}
