import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ContentLoaderComponent } from './content-loader/content-loader.component';
import { LoadingOverlayComponent } from './loading-overlay/loading-overlay.component';

@NgModule({
  imports: [CommonModule, LoadingOverlayComponent, ContentLoaderComponent],
  exports: [ContentLoaderComponent, LoadingOverlayComponent],
})
export class AppSharedUiLoadingModule {}
