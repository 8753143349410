import Ajv from 'ajv';
import addFormats from 'ajv-formats';

export function createAjv(): Ajv {
  const ajv = new Ajv({
    allErrors: true,
    discriminator: true,
  });

  ajv.addFormat('numeric_string', {
    type: 'string',
    validate: (data) => {
      return /^-?\d*\.?\d+$/.test(data);
    },
  });

  ajv.addFormat('int_string', {
    type: 'string',
    validate: (data) => {
      return /^-?\d+$/.test(data);
    },
  });

  addFormats(ajv, {
    formats: ['date', 'date-time', 'int32', 'float', 'email'],
  });

  return ajv;
}
