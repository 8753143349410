export function getHexContrastColor(hexColor: string): string {
  // Convert hex color to RGB
  const hex = hexColor.replace(/#/g, '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Calculate luminance
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // Decide on the contrast color
  return luminance > 0.5 ? '#000000' : '#ffffff';
}
