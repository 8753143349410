import { Dialog } from '@angular/cdk/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentType } from '@angular/cdk/portal';
import {
  ComponentRef,
  DestroyRef,
  Inject,
  Injectable,
  InjectionToken,
  Injector,
  OnDestroy,
  Optional,
  SkipSelf,
  TemplateRef,
  Type,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import {
  Observable,
  Subject,
  defer,
  fromEvent,
  startWith,
  takeUntil,
} from 'rxjs';
import { filter } from 'rxjs/operators';

import { HuskyDrawerConfig } from './drawer-config';
import { HuskyDrawerContainerComponent } from './drawer-container';
import { HuskyDrawerRef } from './drawer-ref';

/** Injection token that can be used to access the data that was passed in to a drawer. */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const HUSKY_DRAWER_DATA = new InjectionToken<any>('HuskyDrawerData');

/** Injection token that can be used to specify default drawer options. */
export const HUSKY_DRAWER_DEFAULT_OPTIONS =
  new InjectionToken<HuskyDrawerConfig>('husky-drawer-default-options');

// Counter for unique dialog ids.
let uniqueId = 0;

export const DRAWER_SKIP_BACK_NAVIGATION = Symbol('HUSKY_CLOSED_FROM_BACK');

/**
 * Service to trigger Material Design bottom sheets.
 */
@Injectable({ providedIn: 'root' })
export class HuskyDrawer implements OnDestroy {
  private readonly _openDrawersAtThisLevel: HuskyDrawerRef<any>[] = [];
  private readonly _afterAllClosedAtThisLevel = new Subject<void>();
  private readonly _afterOpenedAtThisLevel = new Subject<HuskyDrawerRef<any>>();
  private _drawerRefAtThisLevel: HuskyDrawerRef<any> | null = null;
  protected _dialog: Dialog;
  protected drawerConfigClass = HuskyDrawerConfig;

  private readonly _drawerRefConstructor: Type<HuskyDrawerRef<any>>;
  private readonly _drawerContainerType: Type<HuskyDrawerContainerComponent>;
  private readonly _drawerDataToken: InjectionToken<any>;

  /** Reference to the currently opened drawers. */
  get openDrawers(): HuskyDrawerRef<any>[] {
    return this._parentDrawer
      ? this._parentDrawer.openDrawers
      : this._openDrawersAtThisLevel;
  }

  get afterOpened(): Subject<HuskyDrawerRef<any>> {
    return this._parentDrawer
      ? this._parentDrawer.afterOpened
      : this._afterOpenedAtThisLevel;
  }

  private _getAfterAllClosed(): Subject<void> {
    const parent = this._parentDrawer;
    return parent
      ? parent._getAfterAllClosed()
      : this._afterAllClosedAtThisLevel;
  }

  readonly afterAllClosed: Observable<void> = defer(() =>
    this.openDrawers.length
      ? this._getAfterAllClosed()
      : this._getAfterAllClosed().pipe(startWith(undefined)),
  ) as Observable<any>;

  destroyRef = inject(DestroyRef);

  constructor(
    private _overlay: Overlay,
    injector: Injector,
    private readonly router: Router,
    @Optional()
    @Inject(HUSKY_DRAWER_DEFAULT_OPTIONS)
    private _defaultOptions: HuskyDrawerConfig,
    @Optional() @SkipSelf() private _parentDrawer: HuskyDrawer,
  ) {
    this._dialog = injector.get(Dialog);

    this._drawerRefConstructor = HuskyDrawerRef;
    this._drawerContainerType = HuskyDrawerContainerComponent;
    this._drawerDataToken = HUSKY_DRAWER_DATA;
  }

  /**
   * Opens a drawer containing the given component.
   * @param component Type of the component to load into the drawer.
   * @param config Extra configuration options.
   * @returns Reference to the newly-opened drawer.
   */
  open<T, D = any, R = any>(
    component: ComponentType<T>,
    config?: HuskyDrawerConfig<D>,
  ): HuskyDrawerRef<T, R>;
  /**
   * Opens a drawer containing the given template.
   * @param template TemplateRef to instantiate as the drawer content.
   * @param config Extra configuration options.
   * @returns Reference to the newly-opened drawer.
   */
  open<T, D = any, R = any>(
    template: TemplateRef<T>,
    config?: HuskyDrawerConfig<D>,
  ): HuskyDrawerRef<T, R>;
  open<T, D = any, R = any>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    config?: HuskyDrawerConfig<D>,
  ): HuskyDrawerRef<T, R> {
    let drawerRef: HuskyDrawerRef<T, R>;
    config = {
      ...(this._defaultOptions || new HuskyDrawerConfig()),
      ...config,
    };
    config.id = config.id || `husky-drawer-${uniqueId++}`;

    const cdkRef = this._dialog.open<R, D, T>(componentOrTemplateRef, {
      ...config,
      /** Disable closing on navigation, because we want to handle it ourselves */
      closeOnNavigation: false,
      positionStrategy: this._overlay
        .position()
        .global()
        .centerHorizontally()
        .centerVertically(),
      // Disable closing since we need to sync it up to the animation ourselves.
      disableClose: true,
      // Disable closing on destroy, because this service cleans up its open drawers as well.
      // We want to do the cleanup here, rather than the CDK service, because the CDK destroys
      // the drawers immediately whereas we want it to wait for the animations to finish.
      closeOnDestroy: false,
      // Disable closing on detachments so that we can sync up the animation.
      // The Material drawer ref handles this manually.
      closeOnOverlayDetachments: false,
      container: {
        type: this._drawerContainerType,
        providers: () => [
          // Provide our config as the CDK config as well since it has the same interface as the
          // CDK one, but it contains the actual values passed in by the user for things like
          // `disableClose` which we disable for the CDK drawer since we handle it ourselves.
          { provide: this.drawerConfigClass, useValue: config },
          { provide: HuskyDrawerConfig, useValue: config },
        ],
      },
      templateContext: () => ({ drawerRef }),
      providers: (ref, cdkConfig, drawerContainer) => {
        drawerRef = new this._drawerRefConstructor(
          ref,
          config,
          drawerContainer,
        );
        drawerRef.updatePosition(config?.position);
        return [
          { provide: this._drawerContainerType, useValue: drawerContainer },
          { provide: this._drawerDataToken, useValue: cdkConfig.data },
          { provide: this._drawerRefConstructor, useValue: drawerRef },
        ];
      },
    });

    // This can't be assigned in the `providers` callback, because
    // the instance hasn't been assigned to the CDK ref yet.
    (drawerRef! as { componentRef: ComponentRef<T> }).componentRef =
      cdkRef.componentRef!;
    drawerRef!.componentInstance = cdkRef.componentInstance!;

    if (config.closeOnNavigation) {
      this.router.events
        .pipe(
          filter(
            (event): event is NavigationEnd => event instanceof NavigationEnd,
          ),
          takeUntil(drawerRef!.beforeClosed()),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe(() => {
          (drawerRef as HuskyDrawerRef<any>)!.close({
            [DRAWER_SKIP_BACK_NAVIGATION]: true,
          });
        });
    }

    this._drawerOpened(drawerRef!);
    this.afterOpened.next(drawerRef!);

    fromEvent(window, 'popstate')
      .pipe(
        /** Only take events until before the drawer is closed as otherwise we are always sending the result specified here */
        takeUntil(drawerRef!.beforeClosed()),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((event) => {
        const lastDrawer = this.openDrawers[this.openDrawers.length - 1];
        if (lastDrawer) {
          event.preventDefault();
          /** Close drawer with special flag to prevent navigation back, because the history is already cleared */
          lastDrawer.close({
            [DRAWER_SKIP_BACK_NAVIGATION]: true,
          });
        }
      });

    drawerRef!.beforeClosed().subscribe((result) => {
      /** If the drawer was not closed from the back button, we should navigate back to remove the fake history entry */
      if (!(result as any)?.[DRAWER_SKIP_BACK_NAVIGATION]) {
        history.back();
      }
    });

    drawerRef!.afterClosed().subscribe(() => {
      const index = this.openDrawers.indexOf(drawerRef);

      if (index > -1) {
        this.openDrawers.splice(index, 1);

        if (!this.openDrawers.length) {
          this._getAfterAllClosed().next();
        }
      }
    });

    return drawerRef!;
  }

  private _drawerOpened(drawerRef: HuskyDrawerRef<any>): void {
    /** Add fake history entry to allow closing on browser back navigation */
    history.pushState('drawerOpen', '');
    this.openDrawers.push(drawerRef);
  }

  /**
   * Closes all the currently-open drawers.
   */
  closeAll(): void {
    this.openDrawers.forEach((drawer) => drawer.close());
  }

  ngOnDestroy() {
    // Only close the dialogs at this level on destroy
    // since the parent service may still be active.
    this._closeDialogs(this._openDrawersAtThisLevel);
    this._afterAllClosedAtThisLevel.complete();
    this._afterOpenedAtThisLevel.complete();
  }

  private _closeDialogs(dialogs: HuskyDrawerRef<any>[]) {
    let i = dialogs.length;

    while (i--) {
      dialogs[i].close();
    }
  }
}
