import { FormGroup } from '@angular/forms';

import { ErrorModel } from '@husky/app/shared/data-access';

export function mapFieldErrorsToForm(
  errors: ErrorModel[],
  form: FormGroup,
): void {
  for (const error of errors ?? []) {
    if (error.__typename === 'FieldErrorModel') {
      const control = form.get(error.propertyPath);

      if (control) {
        control.setErrors({
          [error.keyword]: error.message,
        });
      }
    }
  }
}
