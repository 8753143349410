@if (loading()) {
  <div
    class="loading-overlay"
    @fadeInOut
    [class.husky-loading-overlay--background-overlay]="backgroundOverlay()"
    [style.position]="position()"
    [class.justify-start]="align() === 'top'"
    [class.justify-center]="align() === 'center'"
    [class.justify-end]="align() === 'bottom'">
    <div class="loader">
      <div class="inner one"></div>
      <div class="inner two"></div>
      <div class="inner three"></div>
    </div>

    <div class="font-medium">{{ text() }}</div>
  </div>
}
