<svg [attr.viewBox]="viewBox" [ngStyle]="style">
  <rect
    [attr.clip-path]="clipPath"
    [ngStyle]="fillStyle"
    role="presentation"
    x="0"
    y="0"
    width="100%"
    height="100%" />

  <defs role="presentation">
    <clipPath [attr.id]="idClip">
      <ng-content></ng-content>
    </clipPath>

    <linearGradient [attr.id]="idGradient">
      <stop
        [attr.stop-color]="backgroundColor"
        [attr.stop-opacity]="backgroundOpacity"
        offset="0%">
        <animate
          *ngIf="animate"
          [attr.keyTimes]="keyTimes"
          [attr.values]="animationValues[0]"
          [attr.dur]="duration"
          attributeName="offset"
          repeatCount="indefinite" />
      </stop>

      <stop
        [attr.stop-color]="foregroundColor"
        [attr.stop-opacity]="foregroundOpacity"
        offset="50%">
        <animate
          *ngIf="animate"
          [attr.keyTimes]="keyTimes"
          [attr.values]="animationValues[1]"
          [attr.dur]="duration"
          attributeName="offset"
          repeatCount="indefinite" />
      </stop>

      <stop
        [attr.stop-color]="backgroundColor"
        [attr.stop-opacity]="backgroundOpacity"
        offset="100%">
        <animate
          *ngIf="animate"
          [attr.keyTimes]="keyTimes"
          [attr.values]="animationValues[2]"
          [attr.dur]="duration"
          attributeName="offset"
          repeatCount="indefinite" />
      </stop>
    </linearGradient>
  </defs>
</svg>
