import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';

import { getHexContrastColor, uniqueRandomHexColor } from '@husky/util-color';

@Component({
  selector: 'husky-app-shared-ui-letter-avatar',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './app-shared-ui-letter-avatar.component.html',
  styleUrl: './app-shared-ui-letter-avatar.component.scss',
})
export class AppSharedUiLetterAvatarComponent {
  private _name: string | undefined;

  @Input({ required: true }) set name(name: string | undefined) {
    this._name = name;
    this.nameInitials = this.splitName(name ?? '');
  }

  @Input()
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' = 'md';

  @HostBinding('class.husky-app-shared-ui-letter-avatar--xs')
  get isXs(): boolean {
    return this.size === 'xs';
  }

  @HostBinding('class.husky-app-shared-ui-letter-avatar--sm')
  get isSmall(): boolean {
    return this.size === 'sm';
  }

  @HostBinding('class.husky-app-shared-ui-letter-avatar--md')
  get isMedium(): boolean {
    return this.size === 'md';
  }

  @HostBinding('class.husky-app-shared-ui-letter-avatar--lg')
  get isLarge(): boolean {
    return this.size === 'lg';
  }

  @HostBinding('class.husky-app-shared-ui-letter-avatar--xl')
  get isExtraLarge(): boolean {
    return this.size === 'xl';
  }

  nameInitials = '';

  splitName(name: string): string {
    return name
      .split(' ')
      .map((n) => n[0])
      .slice(0, 2)
      .join('');
  }

  @HostBinding('style.backgroundColor')
  get backgroundColor(): string {
    if (!this._name) {
      return uniqueRandomHexColor('DEFAULT');
    }

    return uniqueRandomHexColor(this._name);
  }

  @HostBinding('style.color')
  get color(): string {
    if (!this._name) {
      return '#000000';
    }

    return getHexContrastColor(this.backgroundColor);
  }
}
