import { NgIf } from '@angular/common';
import { Component, input } from '@angular/core';
import { MatProgressBar } from '@angular/material/progress-bar';

import { fadeInOut } from '@husky/app/shared/util-animation';

@Component({
  selector: 'husky-loading-bar',
  standalone: true,
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.scss'],
  imports: [NgIf, MatProgressBar],
  animations: [fadeInOut('200ms')],
  host: {
    class: 'absolute top-0 left-0 right-0 w-full',
  },
})
export class LoadingBarComponent {
  loading = input.required<boolean | null>();
}
