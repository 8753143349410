import { ZodIssue } from 'zod';

import { parseJsonPointer } from '@husky/shared/util';

import { AjvError } from './ajvError';

type FieldErrorModel = {
  __typename?: 'FieldErrorModel';
  keyword: string;
  message: string;
  property: string;
  propertyPath: string[];
};

export function ajvErrorToFieldError(error: AjvError): FieldErrorModel {
  return {
    __typename: 'FieldErrorModel',
    property: error.instancePath.split('/').pop() ?? '',
    propertyPath: parseJsonPointer(error.instancePath),
    keyword: error.keyword,
    message: error.message ?? 'This field is invalid.',
  };
}

export function zodErrorToFieldError(issue: ZodIssue): FieldErrorModel {
  return {
    __typename: 'FieldErrorModel',
    property: String(issue.path[issue.path.length - 1]) ?? '',
    propertyPath: issue.path.map(String),
    keyword: issue.code,
    message: issue.message ?? 'This field is invalid.',
  };
}
