import { FocusOrigin } from '@angular/cdk/a11y';
import { DialogRef } from '@angular/cdk/dialog';
import { ESCAPE, hasModifierKey } from '@angular/cdk/keycodes';
import { GlobalPositionStrategy } from '@angular/cdk/overlay';
import { ComponentRef } from '@angular/core';
import { DialogPosition, MatDialogState } from '@angular/material/dialog';
import { Observable, Subject, merge } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { DRAWER_SKIP_BACK_NAVIGATION } from './drawer';
import { HuskyDrawerConfig } from './drawer-config';
import { HuskyDrawerContainerComponent } from './drawer-container';

/**
 * Reference to a drawer dispatched from the drawer service.
 */
export class HuskyDrawerRef<T = any, R = any> {
  /** The instance of component opened into the drawer. */
  componentInstance!: T;

  /**
   * `ComponentRef` of the component opened into the drawer. Will be
   * null when the drawer is opened using a `TemplateRef`.
   */
  readonly componentRef!: ComponentRef<T> | null;

  /** Whether the user is allowed to close the drawer. */
  disableClose: boolean | undefined;

  /** Unique ID for the drawer. */
  id: string;

  /** Subject for notifying the user that the drawer has finished opening. */
  private readonly _afterOpened = new Subject<void>();

  /** Subject for notifying the user that the drawer has started closing. */
  private readonly _beforeClosed = new Subject<R | undefined>();

  /** Result to be passed to afterClosed. */
  private _result: R | undefined;

  /** Handle to the timeout that's running as a fallback in case the exit animation doesn't fire. */
  private _closeFallbackTimeout!: any;

  /** Current state of the drawer. */
  private _state = MatDialogState.OPEN;

  // TODO(crisbeto): we shouldn't have to declare this property, because `DrawerRef.close`
  // already has a second `options` parameter that we can use. The problem is that internal tests
  // have assertions like `expect(MatDrawerRef.close).toHaveBeenCalledWith(foo)` which will break,
  // because it'll be called with two arguments by things like `MatDrawerClose`.
  /** Interaction that caused the drawer to close. */
  private _closeInteractionType: FocusOrigin | undefined;

  constructor(
    private _ref: DialogRef<R, T>,
    config: HuskyDrawerConfig,
    public _containerInstance: HuskyDrawerContainerComponent,
  ) {
    this.disableClose = config.disableClose;
    this.id = _ref.id;

    // Used to target panels specifically tied to drawers.
    _ref.addPanelClass('husky-drawer-panel');

    // Emit when opening animation completes
    _containerInstance._animationStateChanged
      .pipe(
        filter((event) => event.state === 'opened'),
        take(1),
      )
      .subscribe(() => {
        this._afterOpened.next();
        this._afterOpened.complete();
      });

    // Dispose overlay when closing animation is complete
    _containerInstance._animationStateChanged
      .pipe(
        filter((event) => event.state === 'closed'),
        take(1),
      )
      .subscribe(() => {
        clearTimeout(this._closeFallbackTimeout);
        this._finishDrawerClose();
      });

    _ref.overlayRef.detachments().subscribe(() => {
      this._beforeClosed.next(this._result);
      this._beforeClosed.complete();
      this._finishDrawerClose();
    });

    merge(
      this.backdropClick(),
      this.keydownEvents().pipe(
        filter(
          (event) =>
            event.keyCode === ESCAPE &&
            !this.disableClose &&
            !hasModifierKey(event),
        ),
      ),
    ).subscribe((event) => {
      if (!this.disableClose) {
        event.preventDefault();
        _closeDrawerVia(this, event.type === 'keydown' ? 'keyboard' : 'mouse');
      }
    });
  }

  /**
   * Close the drawer.
   * @param drawerResult Optional result to return to the drawer opener.
   */
  close(drawerResult?: R & { [DRAWER_SKIP_BACK_NAVIGATION]?: boolean }): void {
    this._result = drawerResult;

    // Transition the backdrop in parallel to the drawer.
    this._containerInstance._animationStateChanged
      .pipe(
        filter((event) => event.state === 'closing'),
        take(1),
      )
      .subscribe((event) => {
        this._beforeClosed.next(drawerResult);
        this._beforeClosed.complete();
        this._ref.overlayRef.detachBackdrop();

        // The logic that disposes of the overlay depends on the exit animation completing, however
        // it isn't guaranteed if the parent view is destroyed while it's running. Add a fallback
        // timeout which will clean everything up if the animation hasn't fired within the specified
        // amount of time plus 100ms. We don't need to run this outside the NgZone, because for the
        // vast majority of cases the timeout will have been cleared before it has the chance to fire.
        this._closeFallbackTimeout = setTimeout(
          () => this._finishDrawerClose(),
          event.totalTime + 100,
        );
      });

    this._state = MatDialogState.CLOSING;
    this._containerInstance._startExitAnimation();
  }

  /**
   * Gets an observable that is notified when the drawer is finished opening.
   */
  afterOpened(): Observable<void> {
    return this._afterOpened;
  }

  /**
   * Gets an observable that is notified when the drawer is finished closing.
   */
  afterClosed(): Observable<R | undefined> {
    return this._ref.closed;
  }

  /**
   * Gets an observable that is notified when the drawer has started closing.
   */
  beforeClosed(): Observable<R | undefined> {
    return this._beforeClosed;
  }

  /**
   * Gets an observable that emits when the overlay's backdrop has been clicked.
   */
  backdropClick(): Observable<MouseEvent> {
    return this._ref.backdropClick;
  }

  /**
   * Gets an observable that emits when keydown events are targeted on the overlay.
   */
  keydownEvents(): Observable<KeyboardEvent> {
    return this._ref.keydownEvents;
  }

  /**
   * Updates the drawer's position.
   * @param position New drawer position.
   */
  updatePosition(position?: DialogPosition): this {
    const strategy = this._ref.config
      .positionStrategy as GlobalPositionStrategy;

    if (position && (position.left || position.right)) {
      position.left
        ? strategy.left(position.left)
        : strategy.right(position.right);
    } else {
      strategy.centerHorizontally();
    }

    if (position && (position.top || position.bottom)) {
      position.top
        ? strategy.top(position.top)
        : strategy.bottom(position.bottom);
    } else {
      strategy.centerVertically();
    }

    this._ref.updatePosition();

    return this;
  }

  /**
   * Updates the drawer's width and height.
   * @param width New width of the drawer.
   * @param height New height of the drawer.
   */
  updateSize(width = '', height = ''): this {
    this._ref.updateSize(width, height);
    return this;
  }

  /** Add a CSS class or an array of classes to the overlay pane. */
  addPanelClass(classes: string | string[]): this {
    this._ref.addPanelClass(classes);
    return this;
  }

  /** Remove a CSS class or an array of classes from the overlay pane. */
  removePanelClass(classes: string | string[]): this {
    this._ref.removePanelClass(classes);
    return this;
  }

  /** Gets the current state of the drawer's lifecycle. */
  getState(): MatDialogState {
    return this._state;
  }

  /**
   * Finishes the drawer close by updating the state of the drawer
   * and disposing the overlay.
   */
  private _finishDrawerClose() {
    this._state = MatDialogState.CLOSED;
    this._ref.close(this._result, { focusOrigin: this._closeInteractionType });
    this.componentInstance = null!;
  }
}

/**
 * Closes the drawer with the specified interaction type. This is currently not part of
 * `MatDrawerRef` as that would conflict with custom drawer ref mocks provided in tests.
 * More details. See: https://github.com/angular/components/pull/9257#issuecomment-651342226.
 */
// TODO: Move this back into `MatDrawerRef` when we provide an official mock drawer ref.
export function _closeDrawerVia<R>(
  ref: HuskyDrawerRef<R>,
  interactionType: FocusOrigin,
  result?: R,
) {
  (
    ref as unknown as { _closeInteractionType: FocusOrigin }
  )._closeInteractionType = interactionType;
  return ref.close(result);
}
