import { InjectionToken } from '@angular/core';
import Ajv, { Options } from 'ajv';

export const AJV_INSTANCE = new InjectionToken<Ajv>('AJV Instance');
export const AJV_CLASS = new InjectionToken<Ajv>('AJV Class');
export const AJV_CONFIG = new InjectionToken<Ajv>('AJV Class Config');

export function createAjvInstance(
  AjvClass: { new (options: Options): Ajv },
  config: Options,
) {
  return new AjvClass(config);
}
