import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ZodSchema } from 'zod';

export function zodSchemaToValidator<T>(schema: ZodSchema<T>): ValidatorFn {
  return (formGroup: AbstractControl<T>): ValidationErrors | null => {
    const value = formGroup.value;

    const result = schema.safeParse(value);

    if (!result.success) {
      return {
        zodError: result.error.formErrors.formErrors[0] ?? 'Invalid value',
      };
    }

    return null;
  };
}
