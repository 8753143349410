import { DateTime } from 'luxon';
import { z } from 'zod';

export const isoDateZodSchema = z.string().refine(
  (value) => {
    return DateTime.fromISO(value).isValid;
  },
  {
    message: 'Bitte ein gültiges Datum eingeben',
  },
);

export const timeZodSchema = z.string().refine(
  (value) => {
    return DateTime.fromFormat(value, 'HH:mm').isValid;
  },
  {
    message: 'Bitte Uhrzeit im Format HH:mm eingeben',
  },
);
