import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { JSONSchemaType, Options } from 'ajv';

import { FieldErrorModel } from '@husky/app/shared/data-access';
import { ValidationException } from '@husky/app/shared/util-exception';
import {
  AjvError,
  ajvErrorToFieldError,
  createAjv,
} from '@husky/shared/util-ajv';

import { AJV_CONFIG } from './injection-tokens';
import { mapFieldErrorsToForm } from './mapFieldErrorsToForm';

@Injectable({
  providedIn: 'root',
})
export class AjvValidationService {
  constructor(
    private readonly http: HttpClient,
    @Inject(AJV_CONFIG) private readonly ajvConfig: Options,
  ) {}

  isValid<T>(schema: string, data: unknown): data is T {
    // TODO: Change to add options from constructor
    const ajv = createAjv();
    return ajv.validate(schema, data);
  }

  /**
   * Validate data against a schema
   * @param schema The schema to validate against
   * @param data The data to validate
   */
  validate<T>(schema: JSONSchemaType<T>, data: unknown): FieldErrorModel[] {
    // TODO: Change to add options from constructor
    const ajv = createAjv();
    const isValid = ajv.validate(schema, data);

    if (isValid) {
      return [];
    }

    return ((ajv.errors as AjvError[]) ?? []).map(
      (error): FieldErrorModel => ajvErrorToFieldError(error),
    );
  }

  validOrThrow<T>(
    data: unknown,
    options: {
      schema: JSONSchemaType<T>;
      form: FormGroup;
    },
  ): T {
    const errors = this.validate(options.schema, data);
    mapFieldErrorsToForm(errors, options.form);

    if (errors.length !== 0) {
      console.log('ValidationErrors', errors, data);
      throw new ValidationException(errors);
    }

    return data as T;
  }
}
