import { Pipe, PipeTransform } from '@angular/core';

import { getHexContrastColor } from '../getHexContrastColor';

@Pipe({
  name: 'contrastColor',
  standalone: true,
})
export class ContrastColorPipe implements PipeTransform {
  transform(value: string | undefined, ...args: unknown[]): string | undefined {
    if (!value) {
      return undefined;
    }

    return getHexContrastColor(value);
  }
}
