import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'husky-app-shared-ui-drawer-actions',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './app-shared-ui-drawer-actions.component.html',
  styleUrl: './app-shared-ui-drawer-actions.component.scss',
  host: {
    class:
      'flex-none px-gutter py-4 border-t flex items-center gap-2 sticky bottom-0 bg-white',
  },
})
export class AppSharedUiDrawerActionsComponent {}
