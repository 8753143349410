import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { NgModule } from '@angular/core';
import { MatCommonModule } from '@angular/material/core';

import { HuskyDrawer } from './drawer';
import { HuskyDrawerContainerComponent } from './drawer-container';

@NgModule({
  imports: [
    OverlayModule,
    PortalModule,
    MatCommonModule,
    HuskyDrawerContainerComponent,
  ],
  exports: [HuskyDrawerContainerComponent, MatCommonModule],
  providers: [HuskyDrawer],
})
export class HuskyDrawerModule {}
