import { animate, style, transition, trigger } from '@angular/animations';

export const fadeInOut = (delay = '0ms') =>
  trigger('fadeInOut', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate(
        `400ms ${delay} cubic-bezier(0.35, 0, 0.25, 1)`,
        style({ opacity: 1 }),
      ),
    ]),
    transition(':leave', [
      style({ opacity: 1 }),
      animate(`400ms cubic-bezier(0.35, 0, 0.25, 1)`, style({ opacity: 0 })),
    ]),
  ]);
