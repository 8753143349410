import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import Ajv, { Options } from 'ajv';

import {
  AJV_CLASS,
  AJV_CONFIG,
  AJV_INSTANCE,
  createAjvInstance,
} from './injection-tokens';

@NgModule({
  imports: [CommonModule],
  providers: [
    {
      provide: AJV_CLASS,
      useValue: Ajv,
    },
    {
      provide: AJV_CONFIG,
      useValue: {
        allErrors: true,
        discriminator: true,
      } as Options,
    },
    {
      provide: AJV_INSTANCE,
      useFactory: createAjvInstance,
      deps: [AJV_CLASS, AJV_CONFIG],
    },
  ],
})
export class AppSharedUtilOperatorValidationModule {}
